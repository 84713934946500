@import "../../../../../../../../Project/Camfil/code/ui/styles/Camfil/imports";


$name: '.EmbeddedVideo';

#{$name} {
    &-title {
        @include media-breakpoint-down(xs) {
            font-size: 1.125rem;
        }
    }

    &-play {
        background: $primary;
        border-radius: 50% / 10%;
        color: #fff;
        font-size: 1rem;
        height: 3em;
        padding: 0;
        text-align: center;
        text-indent: .1em;
        transition: all .2s ease-out;
        width: 4em;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        z-index: 2;

        &:hover {
            background: darken($primary, 10%);
        }

        &::before {
            position: absolute;
            background: inherit;
            border-radius: 5% / 50%;
            bottom: 9%;
            content: "";
            left: -4%;
            right: -4%;
            top: 9%;
        }

        &::after {
            border-style: solid;
            border-width: 1em 0 1em 1.732em;
            border-color: transparent transparent transparent $white;
            content: ' ';
            font-size: .75em;
            margin: -1em 0 0 -.75em;
            left: 50%;
            top: 50%;
            position: absolute;
        }
    }


    &-wrapper {
        margin: 0 auto;
        max-width: 730px;
    }

    &-videoContainer {
        margin-bottom: 10px;
        position: relative;
        padding-top: 56.25%;
        overflow: hidden;
        cursor: pointer;
    }

    &-poster {
        width: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
        position: absolute;
    }

    &-frame {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }
}
